exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anfrage-gesendet-tsx": () => import("./../../../src/pages/anfrage-gesendet.tsx" /* webpackChunkName: "component---src-pages-anfrage-gesendet-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "slice---src-components-layouts-footer-tsx": () => import("./../../../src/components/layouts/Footer.tsx" /* webpackChunkName: "slice---src-components-layouts-footer-tsx" */),
  "slice---src-components-layouts-header-tsx": () => import("./../../../src/components/layouts/Header.tsx" /* webpackChunkName: "slice---src-components-layouts-header-tsx" */)
}

